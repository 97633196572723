<template>
  <Modulo-Cards-Iframe />
</template>
<script>
import ModuloCardsIframe from "../components/ModuloCardsIframe";

export default {
  name: "modulocardsIframe",
  components: {
    ModuloCardsIframe,
  },
};
</script>
