import Vue from 'vue';
import VueRouter from 'vue-router';
import ModuloCards from '../views/ModuloCards'
import ModuloCardsIframe from '../views/ModuloCardsIframe'
import ModuloCardsListas from '../views/ModuloCardsListas'


Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'TodasProvincias',
        component: () =>
            import ( /* webpackChunkName: "Todas-las-provincias" */ '../views/TodasProvincias.vue')
    },
    {
        path: '/bsas',
        name: 'BuenosAires',
        component: ModuloCards,
    },
    {
        path: '/bsas/:iframe',
        name: 'BuenosAiresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/todas-las-listas',
        name: 'TodasLasListas',
        component: ModuloCardsListas,
    },
    {
        path: '/caba-diputados',
        name: 'CabaDiputados',
        component: ModuloCards,
    },
    {
        path: '/caba-diputados/:iframe',
        name: 'CabaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/catamarca-diputados',
        name: 'CatamarcaDiputados',
        component: ModuloCards,
    },
    {
        path: '/catamarca-diputados/:iframe',
        name: 'CatamarcaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/catamarca-senadores',
        name: 'CatamarcaSenadores',
        component: ModuloCards,
    },
    {
        path: '/catamarca-senadores/:iframe',
        name: 'CatamarcaSenadoresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/cordoba-diputados',
        name: 'CordobaDiputados',
        component: ModuloCards,
    },
    {
        path: '/cordoba-diputados/:iframe',
        name: 'CordobaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/cordoba-senadores',
        name: 'CordobaSenadores',
        component: ModuloCards,
    },
    {
        path: '/cordoba-senadores/:iframe',
        name: 'CordobaSenadoresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/corrientes-diputados',
        name: 'CorrientesDiputados',
        component: ModuloCards,
    },
    {
        path: '/corrientes-diputados/:iframe',
        name: 'CorrientesDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/corrientes-senadores',
        name: 'CorrientesSenadores',
        component: ModuloCards,
    },
    {
        path: '/corrientes-senadores/:iframe',
        name: 'CorrientesSenadoresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/lapampa-diputados',
        name: 'LapampaDiputados',
        component: ModuloCards,
    },
    {
        path: '/lapampa-diputados/:iframe',
        name: 'LapampaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/lapampa-senadores',
        name: 'LapampaSenadores',
        component: ModuloCards,
    },
    {
        path: '/lapampa-senadores/:iframe',
        name: 'LapampaSenadoresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/mendoza-diputados',
        name: 'MendozaDiputados',
        component: ModuloCards,
    },
    {
        path: '/mendoza-diputados/:iframe',
        name: 'MendozaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/mendoza-senadores',
        name: 'MendozaSenadores',
        component: ModuloCards,
    },
    {
        path: '/mendoza-senadores/:iframe',
        name: 'MendozaSenadoresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/santafe-diputados',
        name: 'SantafeDiputados',
        component: ModuloCards,
    },
    {
        path: '/santafe-diputados/:iframe',
        name: 'SantafeDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/santafe-senadores',
        name: 'SantafeSenadores',
        component: ModuloCards,
    },
    {
        path: '/santafe-senadores/:iframe',
        name: 'SantafeSenadoresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/tucuman-diputados',
        name: 'TucumanDiputados',
        component: ModuloCards,
    },
    {
        path: '/tucuman-diputados/:iframe',
        name: 'TucumanDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/tucuman-senadores',
        name: 'TucumanSenadores',
        component: ModuloCards,
    },
    {
        path: '/tucuman-senadores/:iframe',
        name: 'TucumanSenadoresIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/chubut-diputados',
        name: 'ChubutDiputados',
        component: ModuloCards,
    },
    {
        path: '/chubut-diputados/:iframe',
        name: 'ChubutDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/chubut-senadores',
        name: 'ChubutSenadores',
        component: ModuloCards,
    },
    {
        path: '/chubut-senadores/:iframe',
        name: 'ChubutSenadoresIframe',
        component: ModuloCardsIframe,
    },

    {
        path: '/entrerios-diputados',
        name: 'EntreriosDiputados',
        component: ModuloCards,
    },
    {
        path: '/entrerios-diputados/:iframe',
        name: 'EntreriosDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/chaco-diputados',
        name: 'ChacoDiputados',
        component: ModuloCards,
    },
    {
        path: '/chaco-diputados/:iframe',
        name: 'ChacoDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/jujuy-diputados',
        name: 'JujuyDiputados',
        component: ModuloCards,
    },
    {
        path: '/jujuy-diputados/:iframe',
        name: 'JujuyDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/misiones-diputados',
        name: 'MisionesDiputados',
        component: ModuloCards,
    },
    {
        path: '/misiones-diputados/:iframe',
        name: 'MisionesDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/neuquen-diputados',
        name: 'NeuquenDiputados',
        component: ModuloCards,
    },
    {
        path: '/neuquen-diputados/:iframe',
        name: 'NeuquenDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/salta-diputados',
        name: 'SaltaDiputados',
        component: ModuloCards,
    },
    {
        path: '/salta-diputados/:iframe',
        name: 'SaltaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/sanjuan-diputados',
        name: 'SanjuanDiputados',
        component: ModuloCards,
    },
    {
        path: '/sanjuan-diputados/:iframe',
        name: 'SanjuanDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/sanluis-diputados',
        name: 'SanluisDiputados',
        component: ModuloCards,
    },
    {
        path: '/sanluis-diputados/:iframe',
        name: 'SanluisDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/santacruz-diputados',
        name: 'SantacruzDiputados',
        component: ModuloCards,
    },
    {
        path: '/santacruz-diputados/:iframe',
        name: 'SantacruzDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/santiago-diputados',
        name: 'SantiagoDiputados',
        component: ModuloCards,
    },
    {
        path: '/santiago-diputados/:iframe',
        name: 'SantiagoDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/formosa-diputados',
        name: 'FormosaDiputados',
        component: ModuloCards,
    },
    {
        path: '/formosa-diputados/:iframe',
        name: 'FormosaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/larioja-diputados',
        name: 'LariojaDiputados',
        component: ModuloCards,
    },
    {
        path: '/larioja-diputados/:iframe',
        name: 'LariojaDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/rionegro-diputados',
        name: 'RionegroDiputados',
        component: ModuloCards,
    },
    {
        path: '/rionegro-diputados/:iframe',
        name: 'RionegroDiputadosIframe',
        component: ModuloCardsIframe,
    },
    {
        path: '/tdelfuego-diputados',
        name: 'TdelfuegoDiputados',
        component: ModuloCards,
    },
    {
        path: '/tdelfuego-diputados/:iframe',
        name: 'TdelfuegoDiputadosIframe',
        component: ModuloCardsIframe,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;