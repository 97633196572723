export default {
  apiKey: 'AIzaSyCgyHPMGQURa9fMzeSq8I9OkxFthWlhq5w',
  authDomain: 'elecciones-40d37.firebaseapp.com',
  projectId: 'elecciones-40d37',
  storageBucket: 'elecciones-40d37.appspot.com',
  messagingSenderId: '461724436529',
  appId: '1:461724436529:web:e9c0dc3a0a745cff356d10',
  measurementId: 'G-DRCJ0FVDT7',
  databaseURL: 'https://elecciones-40d37-default-rtdb.firebaseio.com/',
};
