<template>
  <Modulo-Cards />
</template>
<script>
import ModuloCards from '../components/ModuloCards';

export default {
  name: 'modulocards',
  components: {
    ModuloCards,
  },
};
</script>