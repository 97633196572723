<template>
  <v-app>
    <v-main>
      <div v-if="isIframe">
        <v-app-bar
          color=" transparent"
          min-height="93"
          max-height="93"
          flat
          style="border-bottom: 1px solid #eee"
        >
          <v-app-bar-nav-icon
            class="mt-3"
            x-large
            @click="drawer = true"
          ></v-app-bar-nav-icon>

          <v-toolbar-title
            class="mt-3 celu"
            style="font-size: 14px; color: #0289cb"
            :set="
              (months = [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Deciembre',
              ])
            "
            >{{ new Date().getDate() }} de
            {{ months[new Date().getMonth()] }} de
            {{ new Date().getFullYear() }}</v-toolbar-title
          >

          <v-img
            class="mt-3 mt-6 flex text-xs-center logo"
            src="/img/logo-239-2020v2.svg"
            max-height="50"
            contain
          >
          </v-img>

          <v-toolbar-title
            class="mt-3 mr-16 celu texto-newsletter"
            style="font-size: 14px; color: #0289cb"
            ><a href="https://www.ambito.com/contenidos/suscripcion.html"
              >SUSCRIBIRSE</a
            >
          </v-toolbar-title>
        </v-app-bar>

        <v-divider />

        <v-app-bar class="link-bar" color="transparent" min-height="75">
          <a class="icon-social" href="https://twitter.com/Ambitocom">
            <v-icon class="mx-2" color="#0289cb">mdi-twitter</v-icon>
          </a>

          <a
            class="icon-social"
            href="https://www.facebook.com/DiarioAmbitoFinanciero"
          >
            <v-icon class="mx-2" color="#0289cb">mdi-facebook</v-icon>
          </a>

          <a
            class="icon-social"
            href="https://www.instagram.com/ambito_financiero/?hl=es-la"
          >
            <v-icon class="mx-2" color="#0289cb">mdi-instagram</v-icon>
          </a>

          <a
            class="icon-social"
            href="https://www.linkedin.com/company/ambito-financiero/"
          >
            <v-icon class="mx-2" color="#0289cb">mdi-linkedin</v-icon>
          </a>

          <a
            class="icon-social"
            href="https://www.youtube.com/channel/UCXKphgduQN0dXiz_ioRTfeA"
          >
            <v-icon class="mx-2" color="#0289cb">mdi-youtube</v-icon>
          </a>

          <v-spacer style="margin-left: -40px" />

          <v-card-title class="mx-2 text-center texto-secciones">
            <a
              class="mx-3"
              href="https://www.ambito.com/contenidos/dolar.html"
              target=""
              title="ámbito.com | Dólar"
              alt="ámbito.com | Dólar"
            >
              DÓLAR
            </a>
            <a
              class="mx-3"
              href="https://www.ambito.com/contenidos/economia.html"
              target=""
              title="ámbito.com | Economía"
              alt="ámbito.com | Economía"
            >
              ECONOMÍA
            </a>
            <a
              class="mx-3"
              href="https://www.ambito.com/contenidos/finanzas.html"
              target=""
              title="ámbito.com | Finanzas"
              alt="ámbito.com | Finanzas"
            >
              FINANZAS
            </a>
            <a
              class="mx-3"
              href="https://www.ambito.com/contenidos/politica.html"
              target=""
              title="ámbito.com | Pólitica"
              alt="ámbito.com | Pólitica"
            >
              POLÍTICA
            </a>
            <a
              class="mx-3"
              href="https://www.ambito.com/contenidos/negocios.html"
              target=""
              title="ámbito.com | Negocios"
              alt="ámbito.com | Negocios"
            >
              NEGOCIOS
            </a>
            <a
              class="mx-3"
              href="https://www.ambito.com/contenidos/edicion-impresa.html"
              target=""
              title="ámbito.com | Economía"
              alt="ámbito.com | Economía"
            >
              EDICIÓN IMPRESA
            </a>
          </v-card-title>

          <v-spacer />
          <p class="mt-6 mr-10 texto-newsletter">
            <a
              class="mx-1"
              href="https://www.ambito.com/contenidos/suscripcion-newsletter.html"
              target=""
              title="ámbito.com | Suscripción al Newsletter"
              alt="ámbito.com | Suscripción al Newsletter"
            >
              NEWSLETTER
            </a>
            <v-img
              style="display: inline-block; vertical-align: middle"
              height="15px"
              width="15px"
              src="/img/icons/mail.svg"
            ></v-img>
          </p>
        </v-app-bar>
        <v-divider />

        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          app
          v-bind:width="306"
        >
          <v-list nav dense>
            <v-list-item-group v-model="group" class="texto-menu">
              <v-list-item @click="drawer = false">
                <v-list-item-icon>
                  <v-icon @click="drawer = false">mdi-close</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  @click="drawer = false"
                  class="mx-3 mt-4 mb-4"
                >
                </v-list-item-title>
              </v-list-item>
              <div style="min-height: 80px"></div>
              <a href="https://www.ambito.com/contenidos/dolar.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >DÓLAR</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/economia.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >ECONOMÍA</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/finanzas.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >FINANZAS</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/politica.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >POLÍTICA</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/negocios.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >NEGOCIOS</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/edicion-impresa.html">
                <v-list-item style="background-color: #6a787e">
                  <v-list-item-title class="mx-3 mt-4 mb-4" style="color: #eee"
                    >EDICIÓN IMPRESA
                  </v-list-item-title>
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/opinion.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >OPINIÓN</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://clima.ambito.com">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >CLIMA</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/lifestyle.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >LIFESTYLE</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/nacional.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >NACIONAL</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/mundo.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >MUNDO
                  </v-list-item-title>
                </v-list-item>
              </a>
              <v-divider />
              <a
                href="https://www.ambito.com/contenidos/informacion-general.html"
              >
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >INFORMACIÓN GENERAL
                  </v-list-item-title>
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/espectaculos.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >ESPECTÁCULOS</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/autos.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >AUTOS
                  </v-list-item-title>
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/deportes.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >DEPORTES</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/c5n-en-vivo.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >C5N EN VIVO</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
              <a href="https://www.ambito.com/contenidos/ultimas-noticias.html">
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >ULTIMAS NOTICIAS
                  </v-list-item-title>
                </v-list-item>
              </a>
              <v-divider />
              <a
                href="https://publicidad.ambito.com/mediakit/ambito/index.html"
              >
                <v-list-item>
                  <v-list-item-title class="mx-3 mt-4 mb-4"
                    >MEDIAKIT</v-list-item-title
                  >
                </v-list-item>
              </a>
              <v-divider />
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <div style="min-height: 15px"></div>
        <h1 class="text-center titulo">
          Resultados <br />Elecciones legislativas
          2021
        </h1>
        <h3
          class="text-center"
          style="font-family: Roboto, Helvetica Neue, Arial, sans-serif"
        >
          Las provincias donde se eligen senadores y diputados.
        </h3>
        <v-spacer />
        <div style="min-height: 15px"></div>
      </div>
      <div class="text-center">
        <v-menu offset-y max-height="300">
          <template  v-slot:activator="{ on, attrs }">
            <v-btn rounded v-bind="attrs" v-on="on">
              Seleccionar provincia »
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="isIframe == 1">
              <v-list-item-title>
                <router-link
                  style="text-decoration: none; line-height: 20px"
                  class="text--primary"
                  to="/"
                >
                  Todas las provicias
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isIframe != 1">
              <v-list-item-title>
                <a
                  style="text-decoration: none; line-height: 20px"
                  class="text--primary"
                  href="https://elecciones.ambito.com"
                  target="_blank"
                >
                  Todas las provicias
                </a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>
                <router-link
                  style="text-decoration: none; line-height: 20px"
                  class="text--primary"
                  :to="armaLink(item.link)"
                >
                  {{ item.title }}
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div style="min-height: 15px"></div>

      <div
        class="text-center ml-4 celu titulodeauna"
        style="font-weight: 900; font-size: 22px"
      >
        {{ poneProvincia()[0] }}
        <span style="font-size: 14px">{{ poneProvincia()[1] }}</span>
      </div>
      <div style="min-height: 5px"></div>
      <div
        class="text-center ml-4 mb-6 desk"
        style="font-weight: 900; font-size: 16px"
      >
        {{ poneProvincia()[0] }}
      </div>

      <router-view />
      <div v-if="isIframe">
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <div style="min-height: 50px"></div>
        <footer class="footer footer-2019 justify-center">
          <!-- START Widget Footer -->
          <div class="widget-wrapper" style="background-image: url('')">
            <section class="footer-top footer-2019">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div style="margin: 0 auto">
                      <a class="brand-logo" href="/">
                        <img
                          src="/img/main-logo-footer-2020v2.svg"
                          data-td-src-property="/img/main-logo-footer-2020v2.svg"
                          alt="ámbito.com"
                          title="ámbito.com"
                          class="brand-logo"
                          width="140"
                          height="40"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="footer-middle footer-2019">
              <div class="container">
                <div class="row no-gutters">
                  <div class="col-10">
                    <div style="margin: 0 auto">
                      <v-card-title class="texto-footer-links">
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/home.html"
                          target=""
                          title="ámbito.com | Portada"
                          alt="ámbito.com | Portada"
                          rel="follow"
                          >Portada</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/economia.html"
                          target=""
                          title="ámbito.com | Economía"
                          alt="ámbito.com | Economía"
                          rel="follow"
                          >Economía</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/banco-datos.html"
                          target=""
                          title="ámbito.com | Banco de Datos"
                          alt="ámbito.com | Banco de Datos"
                          rel="follow"
                          >Banco de Datos</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/finanzas.html"
                          target=""
                          title="ámbito.com | Finanzas"
                          alt="ámbito.com | Finanzas"
                          rel="follow"
                          >Finanzas</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/politica.html"
                          target=""
                          title="ámbito.com | Política"
                          alt="ámbito.com | Política"
                          rel="follow"
                          >Política</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/Mercados.html"
                          target=""
                          title="ámbito.com | Mercados"
                          alt="ámbito.com | Mercados"
                          rel="follow"
                          >Mercados</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/negocios.html"
                          target=""
                          title="ámbito.com | Negocios"
                          alt="ámbito.com | Negocios"
                          rel="follow"
                          >Negocios</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/opinion.html"
                          target=""
                          title="ámbito.com | Opinión"
                          alt="ámbito.com | Opinión"
                          rel="follow"
                          >Opinión</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/lifestyle.html"
                          target=""
                          title="ámbito.com | Lifestyle"
                          alt="ámbito.com | Lifestyle"
                          rel="follow"
                          >Lifestyle</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/nacional.html"
                          target=""
                          title="ámbito.com | Nacional"
                          alt="ámbito.com | Nacional"
                          rel="follow"
                          >Nacional</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/mundo.html"
                          target=""
                          title="ámbito.com | Mundo"
                          alt="ámbito.com | Mundo"
                          rel="follow"
                          >Mundo</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/deportes.html"
                          target=""
                          title="ámbito.com | Deportes"
                          alt="ámbito.com | Deportes"
                          rel="follow"
                          >Deportes</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/edicion-impresa.html"
                          target=""
                          title="ámbito.com | Edición Impresa"
                          alt="ámbito.com | Edición Impresa"
                          rel="follow"
                          >Edición Impresa</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/informacion-general.html"
                          target=""
                          title="ámbito.com | Información General"
                          alt="ámbito.com | Información General"
                          rel="follow"
                          >Información General</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/autos.html"
                          target=""
                          title="ámbito.com | Autos"
                          alt="ámbito.com | Autos"
                          rel="follow"
                          >Autos</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/espectaculos.html"
                          target=""
                          title="ámbito.com | Espectáculos"
                          alt="ámbito.com | Espectáculos"
                          rel="follow"
                          >Espectáculos</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/estilo-a.html"
                          target=""
                          title="ámbito.com | Estilo á"
                          alt="ámbito.com | Estilo á"
                          rel="follow"
                          >Estilo á</a
                        >
                        <a
                          class="mx-3"
                          href="https://www.ambito.com/contenidos/mapa-del-sitio.html"
                          target=""
                          title="ámbito.com | Mapa del Sitio"
                          alt="ámbito.com | Mapa del Sitio"
                          rel="follow"
                          >Mapa del Sitio</a
                        >
                      </v-card-title>

                      <div class="free-text">
                        <header class="headline d-none">
                          <p class="obj-title"></p>
                        </header>
                        <div class="note">
                          <div class="body-content">
                            <p>
                              <strong>
                                <a
                                  href="https://publicidad.ambito.com/mediakit/ambito/index.html"
                                  target="_blank"
                                  rel="nofollow noopener"
                                  >MEDIAKIT</a
                                >
                                CONTACTO</strong
                              >
                              Comercial: digital@ambito.com.ar Tel.&nbsp;
                              <strong>+54 9 11 4023 4077 // </strong>Redacción:
                              redaccionweb@ambito.com.ar
                            </p>
                            <p>
                              Suscripciones:&nbsp; suscripciones@ambito.com.ar
                              Tel.
                              <strong
                                >+54 11 4556 9147 / 48 // +54 9 11 4449
                                3256</strong
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-right">
                    <div
                      class="d-flex flex-column align-items-end"
                      style="margin: 0 auto"
                    >
                      <a
                        class="icon-social"
                        href="https://twitter.com/Ambitocom"
                      >
                        <v-icon class="mx-2" color="#0289cb"
                          >mdi-twitter</v-icon
                        >
                      </a>

                      <a
                        class="icon-social"
                        href="https://www.facebook.com/DiarioAmbitoFinanciero"
                      >
                        <v-icon class="mx-2" color="#0289cb"
                          >mdi-facebook</v-icon
                        >
                      </a>

                      <a
                        class="icon-social"
                        href="https://www.instagram.com/ambito_financiero/?hl=es-la"
                      >
                        <v-icon class="mx-2" color="#0289cb"
                          >mdi-instagram</v-icon
                        >
                      </a>

                      <a
                        class="icon-social"
                        href="https://www.linkedin.com/company/ambito-financiero/"
                      >
                        <v-icon class="mx-2" color="#0289cb"
                          >mdi-linkedin</v-icon
                        >
                      </a>

                      <a
                        class="icon-social"
                        href="https://www.youtube.com/channel/UCXKphgduQN0dXiz_ioRTfeA"
                      >
                        <v-icon class="mx-2" color="#0289cb"
                          >mdi-youtube</v-icon
                        >
                      </a>

                      <p class="mt-6 mr-10 texto-newsletter">
                        <a
                          class="mx-1"
                          href="https://www.ambito.com/contenidos/suscripcion-newsletter.html"
                          target=""
                          title="ámbito.com | Suscripción al Newsletter"
                          alt="ámbito.com | Suscripción al Newsletter"
                        >
                          NEWSLETTER
                        </a>
                        <v-img
                          style="display: inline-block; vertical-align: middle"
                          height="15px"
                          width="15px"
                          src="/img/icons/mail.svg"
                        ></v-img>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="footer-bottom footer-2019">
              <div class="container">
                <div class="row align-items-end">
                  <div class="col-9">
                    <div style="margin: 0 auto">
                      <div class="free-text">
                        <div class="nro-edition">Edición Nº 7669</div>
                        <p>
                          Director: Jorge García - Copyright © 2021 Ámbito.com -
                          RNPI En trámite - Issn 1852 9232 Todos los derechos
                          reservados - Términos y condiciones de uso
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3"></div>
                </div>
              </div>
            </section>
          </div>
          <!-- END Widget Footer -->
        </footer>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    isIframe() {
      return this.$route.params.iframe != "1";
    },
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Resultados Elecciones Legislativas 2021",
    // all titles will be injected into this template
    titleTemplate: "%s | ámbito.com - Resultados - Elecciones legislativas 2021",
    meta: [
      {
        name: "description",
        content:
          "Resultados - Elecciones legislativas 2021",
      },
      {
        property: "og:title",
        content: "Resultados Elecciones Legislativas 2021 | ámbito.com",
      },
      {
        property: "og:site_name",
        content: "ambito.com",
      },
      {
        property: "og:description",
        content:
          "Resultados Elecciones legislativas 2021",
      },
      {
        property: "og:type",
        content: "article",
      },
      {
        property: "og:image",
        content: "https://elecciones.ambito.com/img/urna-elecciones.jpg",
      },
      {
        property: "og:url",
        content: "https://elecciones.ambito.com",
      },
      {
        property: "twitter:title",
        content: "Resultados Elecciones Legislativas 2021 | ámbito.com",
      },
      {
        property: "twitter:description",
        content:
          "Resultados Elecciones legislativas 2021",
      },
      {
        property: "twitter:site",
        content: "@Ambitocom",
      },
      {
        property: "twitter:image",
        content: "https://elecciones.ambito.com/img/urna-elecciones.jpg",
      },
      {
        property: "twitter:creator",
        content: "@Ambitocom",
      },
    ],
  },
  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        title: "Buenos Aires - Diputados Nacionales",
        link: "/bsas",
      },
      {
        title: "CABA - Diputados Nacionales",
        link: "/caba-diputados",
      },
      {
        title: "Córdoba - Diputados Nacionales",
        link: "/cordoba-diputados",
      },
      {
        title: "Córdoba - Senadores Nacionales",
        link: "/cordoba-senadores",
      },
      {
        title: "Santa Fe - Diputados Nacionales",
        link: "/santafe-diputados",
      },
      {
        title: "Santa Fe - Senadores Nacionales",
        link: "/santafe-senadores",
      },
      {
        title: "Mendoza - Diputados Nacionales",
        link: "/mendoza-diputados",
      },
      {
        title: "Mendoza - Senadores Nacionales",
        link: "/mendoza-senadores",
      },
      {
        title: "Tucumán - Diputados Nacionales",
        link: "/tucuman-diputados",
      },
      {
        title: "Tucumán - Senadores Nacionales",
        link: "/tucuman-senadores",
      },
      {
        title: "Catamarca - Diputados Nacionales",
        link: "/catamarca-diputados",
      },
      {
        title: "Catamarca - Senadores Nacionales",
        link: "/catamarca-senadores",
      },
      {
        title: "Corrientes - Diputados Nacionales",
        link: "/corrientes-diputados",
      },
      {
        title: "Corrientes - Senadores Nacionales",
        link: "/corrientes-senadores",
      },
      {
        title: "La Pampa - Diputados Nacionales",
        link: "/lapampa-diputados",
      },
      {
        title: "La Pampa - Senadores Nacionales",
        link: "/lapampa-senadores",
      },

      {
        title: "Chubut - Diputados Nacionales",
        link: "/chubut-diputados",
      },
      {
        title: "Chubut - Senadores Nacionales",
        link: "/chubut-senadores",
      },

      {
        title: "Entre Ríos - Diputados Nacionales",
        link: "/entrerios-diputados",
      },
      {
        title: "Chaco - Diputados Nacionales",
        link: "/chaco-diputados",
      },
      {
        title: "Jujuy - Diputados Nacionales",
        link: "/jujuy-diputados",
      },
      {
        title: "Misiones - Diputados Nacionales",
        link: "/misiones-diputados",
      },
      {
        title: "Neuquén - Diputados Nacionales",
        link: "/neuquen-diputados",
      },
      {
        title: "Salta - Diputados Nacionales",
        link: "/salta-diputados",
      },
      {
        title: "San Juan - Diputados Nacionales",
        link: "/sanjuan-diputados",
      },
      {
        title: "San Luis - Diputados Nacionales",
        link: "/sanluis-diputados",
      },
      {
        title: "Santa Cruz - Diputados Nacionales",
        link: "/santacruz-diputados",
      },
      {
        title: "Santiago del Estero - Diputados Nacionales",
        link: "/santiago-diputados",
      },
      {
        title: "Formosa - Diputados Nacionales",
        link: "/formosa-diputados",
      },
      {
        title: "La Rioja - Diputados Nacionales",
        link: "/larioja-diputados",
      },
      {
        title: "Río Negro - Diputados Nacionales",
        link: "/rionegro-diputados",
      },
      {
        title: "Tierra del Fuego - Diputados Nacionales",
        link: "/tdelfuego-diputados",
      },
    ],

    provinciaNombre: "",
    btn_diputados: "",
  }),
  methods: {
    armaLink(item) {
      let link;
      if (this.$route.params.iframe == 1) {
        this.link = item + "/1";
      } else {
        this.link = item;
      }

      return this.link;
    },
    poneProvincia() {
      let ruta = this.$route.path;
      let ruta_param = ruta.split("/");

      ruta = "/" + ruta_param[1];
      if (ruta == "/") {
        this.provinciaNombre = "";
        this.provinciaBancas = "";
      }
      if (ruta == "/bsas") {
        this.provinciaNombre = "Diputados - Buenos Aires";
        this.provinciaBancas = "(Se renuevan 35 bancas)";
      }
      if (ruta == "/caba-diputados") {
        this.provinciaNombre = "Diputados - CABA";
        this.provinciaBancas = "(Se renuevan 13 bancas)";
      }
      if (ruta == "/catamarca-diputados") {
        this.provinciaNombre =
          "Diputados - Catamarca";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/catamarca-senadores") {
        this.provinciaNombre =
          "Senadores - Catamarca";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/cordoba-diputados") {
        this.provinciaNombre = "Diputados - Córdoba";
        this.provinciaBancas = "(Se renuevan 9 bancas)";
      }
      if (ruta == "/cordoba-senadores") {
        this.provinciaNombre = "Senadores - Córdoba";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/Corrientes-diputados") {
        this.provinciaNombre =
          "Diputados - Corrientes";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/corrientes-senadores") {
        this.provinciaNombre =
          "Senadores - Corrientes";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/lapampa-diputados") {
        this.provinciaNombre =
          "Diputados - La Pampa";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/lapampa-senadores") {
        this.provinciaNombre =
          "Senadores - La Pampa";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/mendoza-diputados") {
        this.provinciaNombre = "Diputados - Mendoza";
        this.provinciaBancas = "(Se renuevan 5 bancas)";
      }
      if (ruta == "/mendoza-senadores") {
        this.provinciaNombre = "Senadores - Mendoza";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/santafe-diputados") {
        this.provinciaNombre =
          "Diputados - Santa Fe";
        this.provinciaBancas = "(Se renuevan 9 bancas)";
      }
      if (ruta == "/santafe-senadores") {
        this.provinciaNombre =
          "Senadores - Santa Fe";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/tucuman-diputados") {
        this.provinciaNombre = "Diputados - Tucumán";
        this.provinciaBancas = "(Se renuevan 4 bancas)";
      }
      if (ruta == "/tucuman-senadores") {
        this.provinciaNombre = "Senadores - Tucumán";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }

      if (ruta == "/chubut-diputados") {
        this.provinciaNombre = "Diputados - Chubut";
        this.provinciaBancas = "(Se renuevan 2 bancas)";
      }
      if (ruta == "/chubut-senadores") {
        this.provinciaNombre = "Senadores - Chubut";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }

      if (ruta == "/entrerios-diputados") {
        this.provinciaNombre =
          "Diputados - Entre Ríos";
        this.provinciaBancas = "(Se renuevan 5 bancas)";
      }
      if (ruta == "/chaco-diputados") {
        this.provinciaNombre = "Diputados - Chaco";
        this.provinciaBancas = "(Se renuevan 4 bancas)";
      }
      if (ruta == "/jujuy-diputados") {
        this.provinciaNombre = "Diputados - Jujuy";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/misiones-diputados") {
        this.provinciaNombre =
          "Diputados - Misiones";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/neuquen-diputados") {
        this.provinciaNombre = "Diputados - Neuquén";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/salta-diputados") {
        this.provinciaNombre = "Diputados - Salta";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/sanjuan-diputados") {
        this.provinciaNombre =
          "Diputados - San Juan";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/sanluis-diputados") {
        this.provinciaNombre =
          "Diputados - San Luis";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/santacruz-diputados") {
        this.provinciaNombre =
          "Diputados - Santa Cruz";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/santiago-diputados") {
        this.provinciaNombre =
          "Diputados - Santiago del Estero";
        this.provinciaBancas = "(Se renuevan 3 bancas)";
      }
      if (ruta == "/formosa-diputados") {
        this.provinciaNombre = "Diputados - Formosa";
        this.provinciaBancas = "(Se renuevan 2 bancas)";
      }
      if (ruta == "/larioja-diputados") {
        this.provinciaNombre =
          "Diputados - La Rioja";
        this.provinciaBancas = "(Se renuevan 2 bancas)";
      }
      if (ruta == "/rionegro-diputados") {
        this.provinciaNombre =
          "Diputados - Río Negro";
        this.provinciaBancas = "(Se renuevan 2 bancas)";
      }
      if (ruta == "/tdelfuego-diputados") {
        this.provinciaNombre =
          "Diputados - Tierra del Fuego";
        this.provinciaBancas = "(Se renuevan 2 bancas)";
      }

      return [this.provinciaNombre, this.provinciaBancas];
    },
  },
};
</script>

<style scoped>
.v-list-item {
  min-height: 29px !important;
}

.desk {
  display: none;
}

.logo {
  left: -50px;
}

@media only screen and (max-width: 600px) {
  .logo {
    left: -30px;
  }

  .celu {
    display: none;
  }

  .link-bar {
    display: none;
  }

  .desk {
    display: block;
  }

  .titulo {
    color: #333;
    font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
    font-size: 1.75em !important;
    font-weight: 700;
  }
}

.texto-secciones a {
  color: #0289cb;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-size: 14px;
  overflow-x: hidden !important;
  max-width: 100vw;
  font-weight: 700;
}

.texto-menu a {
  color: #0289cb;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-size: 14px;
  overflow-x: hidden !important;
  max-width: 100vw;
}

a:visited {
  color: #0289cb !important;
}

.texto-newsletter a {
  color: #000000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-size: 12px;
  overflow-x: hidden !important;
  max-width: 100vw;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
  font-weight: 700;
}

.texto-footer-links a {
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-size: 14px;
  overflow-x: hidden !important;
  max-width: 100vw;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
  font-weight: 100;
}

.icon-social {
  text-decoration: none;
}

.footer-2019 {
  font-size: small;
  text-decoration: none;
}

.titulo {
  color: #333;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
  font-size: 3.75em;
  font-weight: 700;
}

.titulo-provincia {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.color-lista {
  background: #0289cb !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #0289cb !important;
}
.titulodeauna {
  color: #333;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
}
</style>
