<template>
  <Modulo-Cards-Listas />
</template>
<script>
import ModuloCardsListas from '../components/ModuloCardsListas';

export default {
  name: 'modulocardslistas',
  components: {
    ModuloCardsListas,
  },
};
</script>