<template>
<div id="app">
    <h2 class="text-center" style="font-size: 2.5rem">Buenos Aires</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 35 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'bsas')">
            <v-row>
                <v-col v-for="resultado in resultados1" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center rounded-pill" min-height="280" min-width="280" width="288" style="background: rgb(232, 232, 232); background: linear-gradient(140deg, rgba(232, 232, 232, 1) 0%, rgba(229, 229, 238, 1) 28%, rgba(255, 255, 255, 1) 100%);">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>
    <h2 class="text-center" style="font-size: 2.5rem">
        Ciudad de Buenos Aires
    </h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 13 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'caba')">
            <v-row>
                <v-col v-for="resultado in resultados2" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>
    <h2 class="text-center" style="font-size: 2.5rem">Córdoba</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'cordoba_s')">
            <v-row>
                <v-col v-for="resultado in resultados3" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 9 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'cordoba')">
            <v-row>
                <v-col v-for="resultado in resultados4" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Santa Fé</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'santafe_s')">
            <v-row>
                <v-col v-for="resultado in resultados5" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 9 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'santafe')">
            <v-row>
                <v-col v-for="resultado in resultados6" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Mendoza</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'mendoza_s')">
            <v-row>
                <v-col v-for="resultado in resultados7" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 5 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'mendoza')">
            <v-row>
                <v-col v-for="resultado in resultados8" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Tucumán</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'tucuman_s')">
            <v-row>
                <v-col v-for="resultado in resultados9" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 4 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'tucuman')">
            <v-row>
                <v-col v-for="resultado in resultados10" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Catamarca</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'catamarca_s')">
            <v-row>
                <v-col v-for="resultado in resultados11" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'catamarca')">
            <v-row>
                <v-col v-for="resultado in resultados12" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Corrientes</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'corrientes_s')">
            <v-row>
                <v-col v-for="resultado in resultados13" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'corrientes')">
            <v-row>
                <v-col v-for="resultado in resultados14" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">La Pampa</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'lapampa_s')">
            <v-row>
                <v-col v-for="resultado in resultados15" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'lapampa')">
            <v-row>
                <v-col v-for="resultado in resultados16" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Chubut</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Senadores</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'chubut_s')">
            <v-row>
                <v-col v-for="resultado in resultados17" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 2 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'chubut')">
            <v-row>
                <v-col v-for="resultado in resultados18" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Entre Ríos</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 5 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'entrerios')">
            <v-row>
                <v-col v-for="resultado in resultados19" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>
    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Chaco</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 4 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'chaco')">
            <v-row>
                <v-col v-for="resultado in resultados20" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Jujuy</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'jujuy')">
            <v-row>
                <v-col v-for="resultado in resultados21" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Misiones</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'misiones')">
            <v-row>
                <v-col v-for="resultado in resultados22" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Neuquen</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'neuquen')">
            <v-row>
                <v-col v-for="resultado in resultados23" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Salta</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'salta')">
            <v-row>
                <v-col v-for="resultado in resultados24" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">San Juan</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'sanjuan')">
            <v-row>
                <v-col v-for="resultado in resultados25" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">San Luis</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'sanluis')">
            <v-row>
                <v-col v-for="resultado in resultados26" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Santa Cruz</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'santacruz')">
            <v-row>
                <v-col v-for="resultado in resultados27" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Santiago del Estero</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'santiago')">
            <v-row>
                <v-col v-for="resultado in resultados28" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Formosa</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 2 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'formosa')">
            <v-row>
                <v-col v-for="resultado in resultados29" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">La Rioja</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 2 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'larioja')">
            <v-row>
                <v-col v-for="resultado in resultados30" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Río Negro</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 2 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'rionegro')">
            <v-row>
                <v-col v-for="resultado in resultados31" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>

    <div style="height: 40px"></div>

    <h2 class="text-center" style="font-size: 2.5rem">Tierra del Fuego</h2>
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
        <div style="height: 20px"></div>
        <h2 class="ml-10">Diputados</h2>
        <h4 class="ml-10 font-weight-regular">Se renuevan 2 bancas</h4>
        <div style="height: 20px"></div>
        <v-slide-group multiple show-arrows :set="(provincia = 'tdelfuego')">
            <v-row>
                <v-col v-for="resultado in resultados32" :key="resultado.idAgrupacion">
                    <v-slide-item>
                        <v-card color="#f2f2f0" class="mx-auto justify-center" min-height="280" min-width="280" width="288" style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                ">
                            <v-card-title style="font-size: 14px" class="justify-center mb-n4">
                                <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                            </v-card-title>

                            <div v-for="(lista, index) in resultado.listas" :key="lista.idLista" class="text-center" style="font-size: 12px">
                                <v-list-item-avatar v-if="
                      (resultado.listas.length <= 1) &
                      (resultado.listas.length >= 1)
                    " size="120" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <v-list-item-avatar v-else-if="index < 1" size="60" color="grey darken-6" class="mx-auto d-flex">
                                    <v-img class="elevation-6" alt="" :src="
                        cambiaNombre(resultado.nombreAgrupacion, provincia)[1]
                      " />
                                </v-list-item-avatar>

                                <h2 style="font-size:20px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[0] }}
                                </h2>
                                <p style="font-size:18px;">
                                    {{ cambiaNombre(resultado.nombreAgrupacion, provincia)[2] }}
                                </p>
                                <!-- votos: {{ lista.votos }} -->
                                <!-- {{ resultado.idAgrupacion }} -->
                            </div>

                            <v-spacer />

                            <v-card-actions class="ml-n2">
                                <v-progress-linear :color="cambiaColor(resultado.idAgrupacion)" style="position: absolute; bottom: 0" :value="resultado.votosPorcentaje" height="30">
                                    <strong style="font-size: 14px; color: white">{{ cortaTitulo(resultado.nombreAgrupacion) }}
                                        {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong>
                                </v-progress-linear>

                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-col>
            </v-row>
        </v-slide-group>
        <div style="height: 20px"></div>
        <div class="ml-11" style="font-size: 13px">
            <!--  {{ poneMensaje(mesas, fecha_recuento) }}  -->
        </div>
    </v-sheet>
</div>
</template>

<script>
import {
    initializeApp
} from "firebase/app";
import {
    getDatabase,
    ref,
    child,
    get
} from "firebase/database";
import configFirebase from "../configFirebase.js";

export default {
    name: "ModuloCardsListas",

    data() {
        return {
            resultados1: [],
            resultados2: [],
            resultados3: [],
            resultados4: [],
            resultados5: [],
            resultados6: [],
            resultados7: [],
            resultados8: [],
            resultados9: [],
            resultados10: [],
            resultados11: [],
            resultados12: [],
            resultados13: [],
            resultados14: [],
            resultados15: [],
            resultados16: [],
            resultados17: [],
            resultados18: [],
            resultados19: [],
            resultados20: [],
            resultados21: [],
            resultados22: [],
            resultados23: [],
            resultados24: [],
            resultados25: [],
            resultados26: [],
            resultados27: [],
            resultados28: [],
            resultados29: [],
            resultados30: [],
            resultados31: [],
            resultados32: [],
            fecha_recuento: [],
            mesas: [],
            mesa: "",
            fecha: "",
        };
    },
    mounted() {
        this.carga_datos(),
    this.carga_datos_update()
    },
    watch: {
        "$route.path": function () {
            //this.carga_datos();
        },
    },
    methods: {
        carga_datos() {
            const app = initializeApp(configFirebase);
            const dbRef = ref(getDatabase(app));

            get(child(dbRef, `bsas2/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados1 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `caba/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados2 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `cordoba_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados3 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `cordoba/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados4 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `santafe_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados5 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `santafe/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados6 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `mendoza_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados7 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `mendoza/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados8 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `tucuman_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados9 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `tucuman/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados10 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `catamarca_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados11 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `catamarca/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados12 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `corrientes_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados13 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `corrientes/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados14 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `lapampa_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados15 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `lapampa/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados16 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `chubut_s/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados17 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `chubut/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados18 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, `entrerios/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados19 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `chaco/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados20 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `jujuy/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados21 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `misiones/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados22 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `neuquen/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados23 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `salta/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados24 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `sanjuan/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados25 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `sanluis/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados26 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `santacruz/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados27 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `santiago/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados28 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `formosa/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados29 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `larioja/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados30 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `rionegro/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados31 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            get(child(dbRef, `tdelfuego/valoresTotalizadosPositivos`))
                .then((snapshot) => {
                    this.resultados32 = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(child(dbRef, val_provincia + `/fechaTotalizacion`))
                .then((snapshot) => {
                    //console.log(snapshot.val());
                    this.fecha_recuento = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });

            get(
                    child(
                        dbRef,
                        val_provincia + `/estadoRecuento/mesasTotalizadasPorcentaje`
                    )
                )
                .then((snapshot) => {
                    this.mesas = snapshot.val();
                })
                .catch((error) => {
                    console.error(error);
                });
            return "";
        },
        cortaTitulo(agrupacion) {
            let nagrupacion = "";
            switch (agrupacion) {
                case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                    nagrupacion = "FIT - UNIDAD";
                    break;
                case "CORRIENTE DE PENSAMIENTO BONAERENSE":
                    nagrupacion = "CTE DE PENSAMIENTO BONAERENSE";
                    break;
                case "CHACO CAMBIA + JUNTOS POR EL CAMBIO":
                    nagrupacion = "CHACO CAMBIA + JXC";
                    break;
                case "COALICIÓN CÍVICA - AFIRMACIÓN PARA UNA REPÚBLICA IGUALITARIA (ARI)":
                    nagrupacion = "COALICIÓN CÍVICA - ARI";
                    break;
                case "SOMOS ENERGÍA PARA RENOVAR SANTA CRUZ -SER-":
                    nagrupacion = "SOMOS ENERGÍA P R SANTA CRUZ";
                    break;
                case "JUNTOS POR EL CAMBIO TIERRA DEL FUEGO":
                    nagrupacion = "JXC TIERRA DEL FUEGO";
                    break;
                default:
                    nagrupacion = agrupacion;
                    break;
            }
            return nagrupacion;
        },
        poneMensaje(total, fecha) {
            let totalMensaje = "";
            if (fecha != "") {
                return (totalMensaje = `Mesas escrutadas: ${total}% actualizado: ${new Date(
          fecha
        ).toLocaleTimeString()}`);
            }
            return (totalMensaje = "Cargando datos");
        },

        cambiaNombre(nombre, provincia) {
            let candidato = "";
            let candidato2 = "";
            let fotoLista = "";

            //console.log(nombre);
            //buenos aires

            switch (provincia) {
                case "bsas":
                    /*buenos aires*/
                    switch (nombre) {
                        case "+ VALORES":
                            candidato = "Cynthia Liliana Hotton";
                            candidato2 = "Gaston Oscar Bruno";
                            fotoLista = "/img/bsas_cynthia_hotton.jpg";
                            break;

                        case "AVANZA LIBERTAD":
                            candidato = "José Luis Espert";
                            candidato2 = "Carolina Rosana Piparo";
                            fotoLista = "/img/bsas_jose_luis_espert.jpg";
                            break;

                        case "FRENTE VAMOS CON VOS":
                            candidato = "Florencio Randazzo";
                            candidato2 = "Carolina Castro";
                            fotoLista = "/img/bsas_florencio_randazzo.jpg";
                            break;

                        case "JUNTOS":
                            candidato = "Diego Cesar Santilli";
                            candidato2 = "Maria Graciela Ocaña";
                            fotoLista = "/img/bsas_diego_santilli.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Nicolas Del Caño";
                            candidato2 = "Romina Del Pla";
                            fotoLista = "/img/bsas_nicolas_del_cano.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Victoria Tolosa Paz";
                            candidato2 = "Daniel Gustavo Gollan";
                            fotoLista = "/img/bsas_victoria_tolosa_paz.jpg";
                            break;
                    }
                    break;

                case "catamarca":
                    /*catamarca*/
                    switch (nombre) {
                        case "CORRIENTE DE PENSAMIENTO BONAERENSE":
                            candidato = "Jose Luis Centeno";
                            candidato2 = "Ana Beatriz Monllau";
                            fotoLista = "/img/catamarca_jose_luis centeno.jpg";
                            break;

                        case "FRENTE AMPLIO CATAMARQUEÑO":
                            candidato = "Bernardo Ruben Yazbek";
                            candidato2 = "Maria Silvana Pereyra";
                            fotoLista = "/img/catamarca_bernardo_ruben_yazbek.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Maria Estela Moreno";
                            candidato2 = "Daniel Humberto Blanes";
                            fotoLista = "/img/catamarca_maria_estela_moreno.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Silvana Micaela Ginocchio";
                            candidato2 = "Dante Edgardo Lopez Rodriguez";
                            fotoLista = "/img/catamarca_silvana_micaela_ginocchio.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Francisco Manuel Monti";
                            candidato2 = "Patricia Irma Breppe";
                            fotoLista = "/img/catamarca_francisco_manuel_monti.jpg";
                            break;
                    }
                    break;

                case "catamarca_s":
                    switch (nombre) {
                        case "CORRIENTE DE PENSAMIENTO BONAERENSE":
                            candidato = "Jose Alberto Furque";
                            candidato2 = "Claudia Josefina Acosta";
                            fotoLista = "/img/catamarca_jose_alberto_furque.jpg";
                            break;
                        case "FRENTE AMPLIO CATAMARQUEÑO":
                            candidato = "Fernando Daniel Baigorri";
                            candidato2 = "Roxana Beatriz Diaz Vergara";
                            fotoLista = "/img/catamarca_fernando_daniel_baigorri.jpg";
                            break;
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Ariel Antonio Lopez";
                            candidato2 = "Paola Beatriz Diaz";
                            fotoLista = "/img/catamarca_ariel_antonio_lopez.jpg";
                            break;
                        case "FRENTE DE TODOS":
                            candidato = "Lucia Benigna Corpacci";
                            candidato2 = "Guillermo Eduardo Andrada";
                            fotoLista = "/img/catamarca_lucia_benigna_corpacci.jpg";
                            break;
                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Flavio Sergio Fama";
                            candidato2 = "Silvina Maria Acevedo Liedelich";
                            fotoLista = "/img/catamarca_flavio_sergio_fama.jpg";
                            break;
                    }
                    break;

                case "chaco":
                    /*chaco*/
                    switch (nombre) {
                        case "CHACO CAMBIA + JUNTOS POR EL CAMBIO":
                            candidato = "Juan Carlos Polini";
                            candidato2 = "Marilu Quiroz";
                            fotoLista = "/img/chaco_juan_carlos_polini.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Juan Manuel Pedrini";
                            candidato2 = "Maria Luisa Chomiak";
                            fotoLista = "/img/chaco_juan_manuel_pedrini.jpg";
                            break;

                        case "VAMOS CON VOS":
                            candidato = "Carlos Omar Martinez Fidani";
                            candidato2 = "Natalia Antonia Romero";
                            fotoLista = "/img/chaco_carlos_omar_martinez_fidani.jpg";
                            break;

                        case "DEL OBRERO":
                            candidato = "Claudio Ignacio Cobas";
                            candidato2 = "Susana Sanchez";
                            fotoLista = "/img/chaco_claudio_ignacio_cobas.jpg";
                            break;

                        case "FRENTE INTEGRADOR":
                            candidato = "Juan Carlos Bacileff Ivanoff";
                            candidato2 = "Mary Gladys Nuñez";
                            fotoLista = "/img/chaco_juan_carlos_bacileff_ivanoff.jpg";
                            break;
                    }
                    break;

                case "chubut":
                    /*chubut*/
                    switch (nombre) {
                        case "CHUBUT SOMOS TODOS":
                            candidato = "Fabian Alejandro Puratich";
                            candidato2 = "Vanesa Abril";
                            fotoLista = "/img/chubut_fabian_alejandro_puratich.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Maria Eugenia Alianiello";
                            candidato2 = "Rafael De Bernardi";
                            fotoLista = "/img/chubut_maria_eugenia_alianiello.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Daniela Gordiola";
                            candidato2 = "Cesar Antillanca";
                            fotoLista = "/img/chubut_daniela_gordiola.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO CHUBUT":
                            candidato = "Ana Clara Romero";
                            candidato2 = "Alberto Gustavo Menna";
                            fotoLista = "/img/chubut_ana_clara_romero.jpg";
                            break;

                        case "INDEPENDIENTE DEL CHUBUT":
                            candidato = "Maira Raquel Frias";
                            candidato2 = "Antonio Marcelo Rubia";
                            fotoLista = "/img/chubut_maira_raquel_frias.jpg";
                            break;
                    }
                    break;

                case "chubut_s":
                    switch (nombre) {
                        case "CHUBUT SOMOS TODOS":
                            candidato = "Federico Norberto Massoni";
                            candidato2 = "Rosa Rosario Muñoz";
                            fotoLista = "/img/chubut_federico_norberto_massoni.jpg";
                            break;
                        case "FRENTE DE TODOS":
                            candidato = "Carlos Alberto Linares";
                            candidato2 = "Maria Florencia Papaiani";
                            fotoLista = "/img/chubut_carlos_alberto_linares.jpg";
                            break;
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Gloria Saez";
                            candidato2 = "Martin Nicolas Saez";
                            fotoLista = "/img/chubut_gloria_saez.jpg";
                            break;
                        case "JUNTOS POR EL CAMBIO CHUBUT":
                            candidato = "Ignacio 'Nacho' Torres";
                            candidato2 = "Edith Elizabeth Terenzi";
                            fotoLista = "/img/chubut_ignacio_torres.jpg";
                            break;
                        case "INDEPENDIENTE DEL CHUBUT":
                            candidato = "Cesar Hernan Treffinger";
                            candidato2 = "Andrea Alejandra Toro";
                            fotoLista = "/img/chubut_cesar_hernan_treffinger.jpg";
                            break;
                    }
                    break;

                case "caba":
                    /*caba*/
                    switch (nombre) {
                        case "LA LIBERTAD AVANZA":
                            candidato = "Javier Gerardo Milei";
                            candidato2 = "Victoria Eugenia Villarruel";
                            fotoLista = "/img/caba_javier_milei.jpg";
                            break;

                        case "AUTODETERMINACIÓN Y LIBERTAD":
                            candidato = "Luis Fernando Zamora";
                            candidato2 = "Virginia Florencia Peyras Villarino";
                            fotoLista = "/img/caba_luis_zamora.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Myriam Teresa Bregman";
                            candidato2 = "Fernando Sebastián Ramal";
                            fotoLista = "/img/caba_myriam_bregman.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Leandro Jorge Santoro";
                            candidato2 = "Maria Gisela Marziotta";
                            fotoLista = "/img/caba_leandro_santoro.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Maria Eugenia Vidal";
                            candidato2 = "Martin Alberto Tetaz";
                            fotoLista = "/img/caba_maria_eugenia_vidal.jpg";
                            break;
                    }
                    break;

                case "cordoba":
                    /*cordoba*/
                    switch (nombre) {
                        case "ENCUENTRO VECINAL CÓRDOBA":
                            candidato = "Aurelio Francisco Garcia Elorrio";
                            candidato2 = "Memé Moscoso";
                            fotoLista = "/img/cordoba_aurelio_garcia_elorrio.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Liliana Olivero";
                            candidato2 = "Santiago Benitez Vieyra";
                            fotoLista = "/img/cordoba_liliana_olivero.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Martin Rodrigo Gill";
                            candidato2 = "Olga Elena Riutort Lopez";
                            fotoLista = "/img/cordoba_martin_rodrigo_gill.jpg";
                            break;

                        case "HACEMOS POR CÓRDOBA":
                            candidato = "Natalia De La Sota";
                            candidato2 = "Ignacio José Garcia Aresca";
                            fotoLista = "/img/cordoba_natalia_de_la_sota.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Rodrigo De Loredo";
                            candidato2 = "Soledad Carrizo";
                            fotoLista = "/img/cordoba_rodrigo_de_loredo.jpg";
                            break;

                        case "LA LIBERTAD AVANZA":
                            candidato = "Ruben Daniel Petetta";
                            candidato2 = "Maria Cristina Lagger";
                            fotoLista = "/img/cordoba_ruben_daniel_petetta.jpg";
                            break;

                        case "UNIÓN POPULAR FEDERAL":
                            candidato = "Jorge Rafael Scala";
                            candidato2 = "Daniela Welner";
                            fotoLista = "/img/cordoba_jorge_rafael_scala.jpg";
                            break;
                    }
                    break;

                case "cordoba_s":
                    switch (nombre) {
                        case "ENCUENTRO VECINAL CÓRDOBA":
                            candidato = "Juan Pablo Quinteros";
                            candidato2 = "Elena Maria De Las Mercedes Vacchetta";
                            fotoLista = "/img/cordoba_juan_pablo_quinteros.jpg";
                            break;
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Laura Vilches";
                            candidato2 = "Alfredo Leytes";
                            fotoLista = "/img/cordoba_laura_vilches.jpg";
                            break;
                        case "FRENTE DE TODOS":
                            candidato = "Carlos Alberto Caserio";
                            candidato2 = "Gabriela Beatriz Estevez";
                            fotoLista = "/img/cordoba_carlos_alberto_caserio.jpg";
                            break;
                        case "HACEMOS POR CÓRDOBA":
                            candidato = "Alejandra Maria Vigo";
                            candidato2 = "Eduardo Luis Accastello";
                            fotoLista = "/img/cordoba_alejandra_maria_vigo.jpg";
                            break;
                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Luis Juez";
                            candidato2 = "Carmen Alvarez";
                            fotoLista = "/img/cordoba_luis_juez.jpg";
                            break;
                        case "LA LIBERTAD AVANZA":
                            candidato = "Maria Eugenia Gordillo";
                            candidato2 = "Enrique Jose Rigatuso";
                            fotoLista = "/img/cordoba_maria_eugenia_gordillo.jpg";
                            break;
                        case "UNIÓN POPULAR FEDERAL":
                            candidato = "Agustin Alejandro Spaccesi";
                            candidato2 = "Ana Gabriela Nemer Pelliza";
                            fotoLista = "/img/cordoba_agustin_alejandro_spaccesi.jpg";
                            break;
                    }
                    break;

                case "corrientes":
                    /*corrientes*/
                    switch (nombre) {
                        case "FRENTE DE TODOS":
                            candidato = "Jorge Antonio Romero";
                            candidato2 = "Ana Maria Marlene Gauna";
                            fotoLista = "/img/corrientes_jorge_antonio_romero.jpg";
                            break;

                        case "VAMOS CON VOS":
                            candidato = "Silvana Rocio Lagraña";
                            candidato2 = "Juan Pantaleon Eugenio Almada";
                            fotoLista = "/img/corrientes_silvana_rocio_lagraña.jpg";
                            break;

                        case "COMPROMISO FEDERAL":
                            candidato = "Anibal Daniel Godoy";
                            candidato2 = "Leticia Maria Caminos";
                            fotoLista = "/img/corrientes_anibal_daniel_godoy.jpg";
                            break;

                        case "ECO + VAMOS CORRIENTES":
                            candidato = "Manuel 'Colo' Aguirre";
                            candidato2 = "Sofia Brambilla";
                            fotoLista = "/img/corrientes_manuel_aguirre.jpg";
                            break;
                    }
                    break;

                case "corrientes_s":
                    switch (nombre) {
                        case "FRENTE DE TODOS":
                            candidato = "Carlos Mauricio Espinola";
                            candidato2 = "Ana Claudia Almiron";
                            fotoLista = "/img/corrientes_carlos_mauricio_espinola.jpg";
                            break;
                        case "VAMOS CON VOS":
                            candidato = "Maria Eva Romero";
                            candidato2 = "German Gustavo Peralta";
                            fotoLista = "/img/corrientes_maria_eva_romero.jpg";
                            break;
                        case "COMPROMISO FEDERAL":
                            candidato = "Jorge Americo Parthimos";
                            candidato2 = "Mirta Teresa Toffaleti";
                            fotoLista = "/img/corrientes_jorge_americo_parthimos.jpg";
                            break;
                        case "ECO + VAMOS CORRIENTES":
                            candidato = "Eduardo 'Peteco' Vischi";
                            candidato2 = "Mercedes Gabriela Valenzuela";
                            fotoLista = "/img/corrientes_eduardo_vischi.jpg";
                            break;
                    }
                    break;

                case "entrerios":
                    /*entre rios*/
                    switch (nombre) {
                        case "FRENTE DE TODOS":
                            candidato = "Enrique Tomas Cresto";
                            candidato2 = "Ana Carolina Gaillard";
                            fotoLista = "/img/entrerios_enrique_tomas_crespo.jpg";
                            break;

                        case "JUNTOS POR ENTRE RÍOS":
                            candidato = "Rogelio Frigerio";
                            candidato2 = "Marcela Antola";
                            fotoLista = "/img/entrerios_rogelio_frigerio.jpg";
                            break;

                        case "MOVIMIENTO AL SOCIALISMO":
                            candidato = "Federico Demarchi";
                            candidato2 = "Micaela Jorgelina Dalurzo";
                            fotoLista = "/img/entrerios_federico_demarchi.jpg";
                            break;

                        case "NUEVA IZQUIERDA":
                            candidato = "Nadia Gisel Burgos";
                            candidato2 = "Jose Raul Lemes";
                            fotoLista = "/img/entrerios_nadia_gisel_burgos.jpg";
                            break;

                        case "CONSERVADOR POPULAR":
                            candidato = "Miriam Beatriz Muller";
                            candidato2 = "Sebastian Anibal Iglesias";
                            fotoLista = "/img/entrerios_miriam_beatriz_muller.jpg";
                            break;

                        case "FE":
                            candidato = "Lucia  'Luchi' Varisco";
                            candidato2 = "Daniel Anibal Pross";
                            fotoLista = "/img/entrerios_lucia_varisco.jpg";
                            break;

                        case "SOCIALISTA":
                            candidato = "Juan Manuel Rossi";
                            candidato2 = "Graciela Beatriz Brafa";
                            fotoLista = "/img/entrerios_juan_manuel_rossi.jpg";
                            break;
                    }
                    break;

                case "formosa":
                    /*formosa*/
                    switch (nombre) {
                        case "FRENTE DE TODOS":
                            candidato = "Gustavo Ramiro Fernandez Patri";
                            candidato2 = "Elena Maria Garcia";
                            fotoLista = "/img/formosa_gustavo_ramiro_fernandez_patri.jpg";
                            break;

                        case "JUNTOS POR FORMOSA LIBRE":
                            candidato = "Fernando Carbajal";
                            candidato2 = "Elida Emilia Maciel";
                            fotoLista = "/img/formosa_fernando_carabajal.jpg";
                            break;
                            FORMOSA;
                        case "PRINCIPIOS Y CONVICCIÓN":
                            candidato = "Victor Daniel Gonzalez";
                            candidato2 = "Marta Almada";
                            fotoLista = "/img/formosa_victor_daniel_gonzalez.jpg";
                            break;
                    }
                    break;

                case "jujuy":
                    /*jujuy*/
                    switch (nombre) {
                        case "CAMBIA JUJUY":
                            candidato = "Gustavo Bacha Bouhid";
                            candidato2 = "Natalia Silvina Sarapura";
                            fotoLista = "/img/jujuy_gustavo_bacha_bouhid.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Alejandro Ariel Vilca";
                            candidato2 = "Natalia Andrea Morales";
                            fotoLista = "/img/jujuy_alejandro_ariel_vilca.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Leila Susana Chaher";
                            candidato2 = "Rodolfo Alejandro Tecchi";
                            fotoLista = "/img/jujuy_leila_susana_chaher.jpg";
                            break;
                    }
                    break;

                case "lapampa":
                    /*la pampa*/
                    switch (nombre) {
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Luciano Gonzalez Cabiati";
                            candidato2 = "Julia Baleani";
                            fotoLista = "/img/lapampa_luciano_gonzalez_cabiati.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Varinia Lichi Marin";
                            candidato2 = "Ariel Rauschenberger";
                            fotoLista = "/img/lapampa_virinia_lichi_marin.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Martin Maquieyra";
                            candidato2 = "Marcela Coli";
                            fotoLista = "/img/lapampa_martin_maquieyra.jpg";
                            break;

                        case "MOVIMIENTO AL SOCIALISMO":
                            candidato = "Ayelen Pilcic";
                            candidato2 = "Cristian Santiago Bustamante";
                            fotoLista = "/img/lapampa_ayelen_pilcic.jpg";
                            break;

                        case "SOCIALISTA":
                            candidato = "Marina Jacoba Vanini";
                            candidato2 = "Ariel Alberto Toselli";
                            fotoLista = "/img/lapampa_marina_jacoba_vanini.jpg";
                            break;
                    }
                    break;

                case "lapampa_s":
                    switch (nombre) {
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Claudia Eugenia Lupardo";
                            candidato2 = "Gustavo Daniel Mrongowius";
                            fotoLista = "/img/lapampa_claudia_lupardo.jpg";
                            break;
                        case "FRENTE DE TODOS":
                            candidato = "Daniel Pali Bensusan";
                            candidato2 = "Maria Luz Luchy Alonso";
                            fotoLista = "/img/lapampa_daniel_pali_bensusan.jpg";
                            break;
                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Daniel Ricardo Kroneberger";
                            candidato2 = "Maria Victoria Huala";
                            fotoLista = "/img/lapampa_daniel_ricardo_kroneberger.jpg";
                            break;
                        case "MOVIMIENTO AL SOCIALISMO":
                            candidato = "Maite Perez Garcia";
                            candidato2 = "Victor Andres Balmaceda";
                            fotoLista = "/img/lapampa_maite_perez_garcia.jpg";
                            break;
                        case "SOCIALISTA":
                            candidato = "Luis Hernan Solana";
                            candidato2 = "Hilda Graciela Somoza";
                            fotoLista = "/img/lapampa_luis_hernan_solana.jpg";
                            break;
                    }
                    break;

                case "larioja":
                    /*la rioja*/
                    switch (nombre) {
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Majo Gonzalez";
                            candidato2 = "Martin Abelardo Angel";
                            fotoLista = "/img/larioja_majo_gonzalez.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Gabriela Pedrali";
                            candidato2 = "Jorge Ricardo Herrera";
                            fotoLista = "/img/larioja_gabriela_pedrali.jpg";
                            break;

                        case "UNION DEL CENTRO DEMOCRATICO":
                            candidato = "Cristian Oscar Corzo";
                            candidato2 = "Susana Del Carmen Rizzo";
                            fotoLista = "/img/larioja_cristian_oscar_corzo.jpg";
                            break;

                        case "UNIDOS":
                            candidato = "Cristian Avilar";
                            candidato2 = "Maria Eugenia Perez";
                            break;

                        case "VAMOS LA RIOJA":
                            candidato = "Juan Amado";
                            candidato2 = "Luciana De Leon";
                            fotoLista = "/img/larioja_juan_amado.jpg";
                            break;
                    }
                    break;

                case "mendoza":
                    /*mendoza*/
                    switch (nombre) {
                        case "CAMBIA MENDOZA":
                            candidato = "Julio Cobos";
                            candidato2 = "Pamela Verasay";
                            fotoLista = "/img/mendoza_julio_cobos.jpg";
                            break;

                        case "FEDERAL":
                            candidato = "Flavia Maria Manoni";
                            candidato2 = "Vicente Javier Espeche";
                            fotoLista = "/img/mendoza_flavia_maria_manoni.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Noelia Barbeito";
                            candidato2 = "Victor Da Vila";
                            fotoLista = "/img/mendoza_noelia_barbeito.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Adolfo Bermejo";
                            candidato2 = "Lili Paponet";
                            fotoLista = "/img/mendoza_adolfo_bermejo.jpg";
                            break;

                        case "COMPROMISO FEDERAL":
                            candidato = "Carlos Iannizzotto";
                            candidato2 = "Laura Farfán";
                            fotoLista = "/img/mendoza_carlos_iannizzotto.jpg";
                            break;

                        case "VERDE":
                            candidato = "Mario Nicolas Vadillo";
                            candidato2 = "Berta Susana Matas Pijuan";
                            fotoLista = "/img/mendoza_mario_nicolas_vadillo.jpg";
                            break;

                        case "VAMOS ! MENDOCINOS":
                            candidato = "Gustavo Eduardo Gutierrez";
                            candidato2 = "Cecilia Torrez";
                            fotoLista = "/img/mendoza_gustavo_eduardo_gutierrez.jpg";
                            break;
                    }
                    break;

                case "mendoza_s":
                    switch (nombre) {
                        case "CAMBIA MENDOZA":
                            candidato = "Alfredo Cornejo";
                            candidato2 = "Mariana Juri";
                            fotoLista = "/img/mendoza_alfredo_cornejo.jpg";
                            break;
                        case "COMPROMISO FEDERAL":
                            candidato = "Jorge David Jose Pujol";
                            candidato2 = "Adriana Emilce Bonadeo";
                            fotoLista = "/img/mendoza_jorge_david_jose_pujol.jpg";
                            break;
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Lautaro Jimenez";
                            candidato2 = "Raquel Blas";
                            fotoLista = "/img/mendoza_lautaro_jimenez.jpg";
                            break;
                        case "FRENTE DE TODOS":
                            candidato = "Anabel Fernandez Sagasti";
                            candidato2 = "Martín Aveiro";
                            fotoLista = "/img/mendoza_anabel_fernandez_sagasti.jpg";
                            break;
                        case "FEDERAL":
                            candidato = "Gustavo Majstruk";
                            candidato2 = "Myriam Alvarez";
                            fotoLista = "/img/mendoza_gustavo_majstruk.jpg";
                            break;
                        case "VERDE":
                            candidato = "Marcelo Martin Romano";
                            candidato2 = "Elsa Maria Ivana Vercelli";
                            fotoLista = "/img/mendoza_marcelo_martin_romano.jpg";
                            break;
                        case "VAMOS ! MENDOCINOS":
                            candidato = "Maria Mercedes Llano";
                            candidato2 = "Pedro Alberto Garcia Espetxe";
                            fotoLista = "/img/mendoza_maria_mercedes_llano.jpg";
                            break;
                    }
                    break;

                case "misiones":
                    /*misiones*/
                    switch (nombre) {
                        case "DEL OBRERO":
                            candidato = "Eduardo D. Cantero";
                            candidato2 = "L. Virginia Villanueva";
                            fotoLista = "/img/misiones_eduardo_cantero.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Pablo Isaac Lenguaza";
                            candidato2 = "Graciela B. De Melo";
                            fotoLista = "/img/misiones_pablo_isaac_lenguaza.jpg";
                            break;

                        case "FRENTE JUNTOS POR EL CAMBIO":
                            candidato = "Martin A. Arjol";
                            candidato2 = "Florencia Klipauka Lewtak";
                            fotoLista = "/img/misiones_martin_arjol.jpg";
                            break;

                        case "FRENTE RENOVADOR DE LA CONCORDIA":
                            candidato = "Carlos Alberto Fernandez";
                            candidato2 = "Claudia Noemi Gauto";
                            fotoLista = "/img/misiones_carlos_alberto_fernandez.jpg";
                            break;

                        case "LIBERTAD, VALORES Y CAMBIO":
                            candidato = "Ninfa Zunilda Alvarenga";
                            candidato2 = "Mauricio Rolando Czerevin";
                            fotoLista = "/img/misiones_ninfa_zunilda alvarenga.jpg";
                            break;
                    }
                    break;

                case "neuquen":
                    /*neuquen*/
                    switch (nombre) {
                        case "CAMBIA NEUQUÉN":
                            candidato = "Pablo Cervi";
                            candidato2 = "Ayelen Quiroga";
                            fotoLista = "/img/neuquen_pablo_cervi.jpg";
                            break;

                        case "COALICIÓN CÍVICA - AFIRMACIÓN PARA UNA REPÚBLICA IGUALITARIA (ARI)":
                            candidato = "Carlos  Eguia";
                            candidato2 = "Cata Uleri";
                            fotoLista = "/img/neuquen_carlos_eguia.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Raul Godoy";
                            candidato2 = "Patricia Jure";
                            fotoLista = "/img/neuquen_raul_godoy.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Tanya  Bertoldi";
                            candidato2 = "Beto Vivero";
                            fotoLista = "/img/neuquen_tanya_bertoldi.jpg";
                            break;

                        case "MOVIMIENTO LIBRES DEL SUR":
                            candidato = "Jesus Escobar";
                            candidato2 = "Valeria  Amstein";
                            fotoLista = "/img/neuquen_jesus_escobar.jpg";
                            break;

                        case "MOVIMIENTO POPULAR NEUQUINO":
                            candidato = "'Rolo' Figueroa";
                            candidato2 = "Luciana Ortiz Luna";
                            fotoLista = "/img/neuquen_figueroa.jpg";
                            break;

                        case "SOCIALISTA":
                            candidato = "Sandrita Ferrero";
                            candidato2 = "Luis Virgilio Sanchez";
                            fotoLista = "/img/neuquen_sandrita_ferrero.jpg";
                            break;
                    }
                    break;

                case "rionegro":
                    /*rio negro*/
                    switch (nombre) {
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Norma  Dardik";
                            candidato2 = "Jorge Paulic";
                            fotoLista = "/img/rionegro_norma_dardik.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Ana Ines Marks";
                            candidato2 = "Jose Luis Berros";
                            fotoLista = "/img/rionegro_ana_ines_marks.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Anibal Tortoriello";
                            candidato2 = "Adriana Fenouil";
                            fotoLista = "/img/rionegro_anibal_tortoriello.jpg";
                            break;

                        case "JUNTOS SOMOS RIO NEGRO":
                            candidato = "Agustin Domingo";
                            candidato2 = "Maria De Las Mercedes Ibero";
                            fotoLista = "/img/rionegro_agustin_domingo.jpg";
                            break;

                        case "MOVIMIENTO AL SOCIALISMO":
                            candidato = "Elena Beatriz Correa";
                            candidato2 = "Aquiles Añazco Nieto";
                            fotoLista = "/img/rionegro_elena_beatriz_correa.jpg";
                            break;

                        case "SOCIALISTA":
                            candidato = "Paolo Adrian Etchepareborda";
                            candidato2 = "Andrea Alejandra Mancardo";
                            fotoLista = "/img/rionegro_paolo_adrian_etchepareborda.jpg";
                            break;
                    }
                    break;

                case "salta":
                    /*salta*/
                    switch (nombre) {
                        case "FELICIDAD":
                            candidato = "Tane Da Souza";
                            candidato2 = "Maria Laura 'Cotty' Thomas";
                            fotoLista = "/img/salta_tane_da_souza.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Andrea  Villegas";
                            candidato2 = "Mario Silva";
                            fotoLista = "/img/salta_andrea_villegas.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Emiliano Estrada";
                            candidato2 = "Pamela Calletti";
                            fotoLista = "/img/salta_emiliano_estrada.jpg";
                            break;

                        case "FRENTE SI + PRS":
                            candidato = "Felipe Guillermo Biella Calvet";
                            candidato2 = "Elsa Del Valle Pereyra Maidana";
                            fotoLista = "/img/salta_felipe_guillermo_biella_calvet.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO +":
                            candidato = "Carlos Raúl Zapata";
                            candidato2 = "Ines Liendo";
                            fotoLista = "/img/salta_carlos_raúl_zapata.jpg";
                            break;

                        case "POLITICA OBRERA":
                            candidato = "Julio Oscar Quintana";
                            candidato2 = "Gloria Daniela Romano Ortiz";
                            fotoLista = "/img/salta_julio_oscar_quintana.jpg";
                            break;

                        case "UNIDOS POR SALTA":
                            candidato = "Guillermo Mario Durand Cornejo";
                            candidato2 = "Silvia Natalia Loria";
                            fotoLista = "/img/salta_guillermo_mario_durand_cornejo.jpg";
                            break;
                    }
                    break;

                case "sanjuan":
                    /*san juan*/
                    switch (nombre) {
                        case "CONSENSO ISHCHIGUALASTO":
                            candidato = "Marcelo Alberto Arancibia";
                            candidato2 = "Susana Norma Ocampo";
                            fotoLista = "/img/sanjuan_marcelo_alberto_arancibia.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Cristian Jurado";
                            candidato2 = "Mary Garrido";
                            fotoLista = "/img/sanjuan_cristian_jurado.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Walberto Enrique Allende";
                            candidato2 = "Ana Fabiola Aubone";
                            fotoLista = "/img/sanjuan_walberto_enrique_allende.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Susana Alicia Laciar";
                            candidato2 = "Enzo Ariel Cornejo";
                            fotoLista = "/img/sanjuan_susana_alicia_laciar.jpg";
                            break;
                    }
                    break;

                case "sanluis":
                    /*san luis*/
                    switch (nombre) {
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Johana Gómez";
                            candidato2 = "Juan Zárate";
                            fotoLista = "/img/sanluis_johana_gomez.jpg";
                            break;

                        case "FUERZA SAN LUIS":
                            candidato = "Maria Jose Zangla Urteaga";
                            candidato2 = "Eduardo Gaston Ramon Mones Ruiz";
                            fotoLista = "/img/sanluis_maria_jose_zangla_urteaga.jpg";
                            break;

                        case "UNIDOS POR SAN LUIS":
                            candidato = "Claudio Javier Poggi";
                            candidato2 = "Karina Bachey";
                            fotoLista = "/img/sanluis_claudio_javier_poggi.jpg";
                            break;

                        case "TODOS UNIDOS":
                            candidato = "Alfonso Hernan Verges";
                            candidato2 = "Berta Hortensia Arenas";
                            fotoLista = "/img/sanluis_alfonso_hernan_verges.jpg";
                            break;

                        case "UNIÓN CÍVICA RADICAL":
                            candidato = "Jose Luis Riccardo";
                            candidato2 = "Julia Moyano";
                            fotoLista = "/img/sanluis_jose_luis_riccardo.jpg";
                            break;
                    }
                    break;

                case "santacruz":
                    /*santa cruz*/
                    switch (nombre) {
                        case "CAMBIA SANTA CRUZ":
                            candidato = "Roxana Reyes";
                            candidato2 = "Leo Roquel";
                            fotoLista = "/img/santacruz_roxana_reyes.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Nicolas Ignacio Gutierrez";
                            candidato2 = "Yesica Paola Aguilar";
                            fotoLista = "/img/santacruz_nicolas_ignacio_gutierrez.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Gustavo Carlos Miguel Gonzalez";
                            candidato2 = "Moira Agostina Lanesan Sancho";
                            fotoLista = "/img/santacuz_gustavo_carlos_miguel_gonzalez.jpg";
                            break;

                        case "MOVIMIENTO AL SOCIALISMO":
                            candidato = "Jorge Jesus Mariano";
                            candidato2 = "Graciela Ermelinda Linares";
                            fotoLista = "/img/santacruz_jorge_jesus_mariano.jpg";
                            break;

                        case "SOMOS ENERGÍA PARA RENOVAR SANTA CRUZ -SER-":
                            candidato = "Claudio Orlando Vidal";
                            candidato2 = "Monica Alejandra Pereyra";
                            fotoLista = "/img/santacruz_claudio_orlando_vidal.jpg";
                            break;
                    }
                    break;

                case "santafe":
                    /*santa fe*/
                    switch (nombre) {
                        case "FRENTE AMPLIO PROGRESISTA":
                            candidato = "Monica Fein";
                            candidato2 = "Gonzalo  Saglione";
                            fotoLista = "/img/santafe_monica_fein.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Carla Deiana";
                            candidato2 = "Octavio Crivaro";
                            fotoLista = "/img/santafe_carla_deiana.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Roberto Mario Mirabella";
                            candidato2 = "Magali Mastaler";
                            fotoLista = "/img/santafe_roberto_mario_mirabella.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Mario Domingo Barletta";
                            candidato2 = "Victoria Tejeda";
                            fotoLista = "/img/santafe_mario_domingo_barletta.jpg";
                            break;

                        case "PODEMOS":
                            candidato = "Patricia Silva De Cattaneo";
                            candidato2 = "Nahuel Rene Bay";
                            fotoLista = "/img/santafe_patricia_silva_de_cattaneo.jpg";
                            break;

                        case "PRIMERO SANTA FE":
                            candidato = "Mario Atilio Deschi";
                            candidato2 = "Gabriela Liliana Arrieta";
                            fotoLista = "/img/santafe_mario_atilio_deschi.jpg";
                            break;

                        case "SOBERANIA POPULAR":
                            candidato = "Carlos Del Frade";
                            candidato2 = "Eliana Tedini";
                            fotoLista = "/img/santafe_carlos_del_frade.jpg";
                            break;

                        case "SOMOS FUTURO":
                            candidato = "Coty Hernandez";
                            candidato2 = "Belu Gomez";
                            fotoLista = "/img/santafe_coty_hernandez.jpg";
                            break;

                        case "UNITE POR LA LIBERTAD Y LA DIGNIDAD":
                            candidato = "Alejandra 'Locomotora' Oliveras";
                            candidato2 = "Cristian Fernando Hoffmann";
                            fotoLista = "/img/santafe_alejandra_oliveras.jpg";
                            break;
                    }
                    break;
                case "santafe_s":
                    switch (nombre) {
                        case "FRENTE AMPLIO PROGRESISTA":
                            candidato = "Clara Garcia";
                            candidato2 = "Paco  Garibaldi";
                            fotoLista = "/img/santafe_clara_garcia.jpg";
                            break;
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Irene Gamboa";
                            candidato2 = "Luciano Caceres";
                            fotoLista = "/img/santafe_irene_gamboa.jpg";
                            break;
                        case "FRENTE DE TODOS":
                            candidato = "Marcelo Nestor Lewandowski";
                            candidato2 = "Maria De Los Angeles Sacnun";
                            fotoLista = "/img/santafe_marcelo_nestor_lewandowski.jpg";
                            break;
                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Carolina Losada";
                            candidato2 = "Dionisio Fernando Scarpin";
                            fotoLista = "/img/santafe_carolina_losada.jpg";
                            break;
                        case "PODEMOS":
                            candidato = "Juan Domingo Argañaraz";
                            candidato2 = "Maria Ines D'Angelo";
                            fotoLista = "/img/santafe_juan_domingo_argañaraz.jpg";
                            break;
                        case "PRIMERO SANTA FE":
                            candidato = "Jorge Rosario Boasso";
                            candidato2 = "Maria Virginia Borga";
                            fotoLista = "/img/santafe_jorge_rosario_boasso.jpg";
                            break;
                        case "SOBERANIA POPULAR":
                            candidato = "Mercedes Meier";
                            candidato2 = "Javier Barbona";
                            fotoLista = "/img/santafe_mercedes_meier.jpg";
                            break;
                        case "SOMOS FUTURO":
                            candidato = "Patricia  Frausin";
                            candidato2 = "Bichi Sfiligoy";
                            fotoLista = "/img/santafe_patricia_frausin.jpg";
                            break;
                        case "UNITE POR LA LIBERTAD Y LA DIGNIDAD":
                            candidato = "Betina Ines Florito";
                            candidato2 = "Ignacio De Biasi";
                            fotoLista = "/img/santafe_betina_ines_florito.jpg";
                            break;
                    }
                    break;

                case "santiago":
                    /*santiago del estero*/
                    switch (nombre) {
                        case "FRENTE CIVICO POR SANTIAGO":
                            candidato = "Silvia Noemi Del Valle Sayago";
                            candidato2 = "Bernardo Jose Herrera";
                            fotoLista =
                                "/img/santiagodelestero_silvia_noemi_del_valle_sayago.jpg";
                            break;

                        case "FRENTE PATRIOTICO LABORISTA":
                            candidato = "Guillermo Daniel Suarez Melean";
                            candidato2 = "Alicia Del Valle Vazquez";
                            fotoLista =
                                "/img/santiagodelestero_guillermo_daniel_suarez_melean.jpg";
                            break;

                        case "FRENTE RENOVADOR":
                            candidato = "Pablo Guillermo Mirolo";
                            candidato2 = "Veronica Alejandra Larcher";
                            fotoLista = "/img/santiagodelestero_pablo_guillermo_mirolo.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO":
                            candidato = "Facundo Jose Perez Carletti";
                            candidato2 = "Luciana Rached";
                            fotoLista =
                                "/img/santiagodelestero_facundo_jose_perez_carletti.jpg";
                            break;

                        case "MOVIMIENTO LIBRES DEL SUR":
                            candidato = "Marianella Lezama Hid";
                            candidato2 = "Patricio Nicolas Augusto";
                            fotoLista = "/img/santiagodelestero_marianella_lezama_hid.jpg";
                            break;

                        case "POR UN SANTIAGO OBRERO":
                            candidato = "Norma Valeria Morales";
                            candidato2 = "Alvaro Fabian Reynoso";
                            fotoLista = "/img/santiagodelestero_norma_valeria_morales.jpg";
                            break;

                        case "UNITE POR LA LIBERTAD Y LA DIGNIDAD":
                            candidato = "Luciano Antonio Pavan";
                            candidato2 = "Lorena Natalia Flores";
                            fotoLista = "/img/santiagodelestero_luciano_antonio_pavan.jpg";
                            break;
                    }
                    break;

                case "tdelfuego":
                    /*tierra del fuego*/
                    switch (nombre) {
                        case "FRENTE DE TODOS":
                            candidato = "Ines Carolina Yutrovic";
                            candidato2 = "Guillermo Daniel Fernandez";
                            fotoLista = "/img/tdelfuego_ines_carolina_yutrovic.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO TIERRA DEL FUEGO":
                            candidato = "Tito Stefani";
                            candidato2 = "Dalila Nora";
                            fotoLista = "/img/tdelfuego_tito_stefani.jpg";
                            break;

                        case "MOVIMIENTO AL SOCIALISMO":
                            candidato = "Eduardo Juan Cenatiempo";
                            candidato2 = "Adriana Monica Blanco";
                            fotoLista = "/img/tdelfuego_eduardo_juan_cenatiempo.jpg";
                            break;

                        case "MOVIMIENTO POPULAR FUEGUINO":
                            candidato = "Javier Omar Giordano";
                            candidato2 = "Gissel Teresita Bertotto Collazo";
                            fotoLista = "/img/tdelfuego_javier_omar_giordano.jpg";
                            break;

                        case "OBRERO":
                            candidato = "Daniel Patricio Barria Carabajal";
                            candidato2 = "Lucia Zulma Fernandez";
                            fotoLista = "/img/tdelfuego_daniel_patricio_barria_carabajal.jpg";
                            break;

                        case "REPUBLICANOS UNIDOS":
                            candidato = "Samuel Martin Muñoz Letizia";
                            candidato2 = "Barbara Veron";
                            fotoLista = "/img/tdelfuego_samuel_martin_muñoz_letizia.jpg";
                            break;

                        case "SOMOS FUEGUINOS":
                            candidato = "Liliana Fadul 'Chispita'";
                            candidato2 = "Conrado Zamora";
                            fotoLista = "/img/tdelfuego_liliana_fadul.jpg";
                            break;
                    }
                    break;

                case "tucuman":
                    /*tucuman*/
                    switch (nombre) {
                        case "FRENTE AMPLIO POR TUCUMÁN":
                            candidato = "Maria Florencia Guerra";
                            candidato2 = "Guillermo Dalmacio Correa";
                            fotoLista = "/img/tucuman_maria_florencia_guerra.jpg";
                            break;

                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Alejandra Arreguez";
                            candidato2 = "Juan Jose Paz";
                            fotoLista = "/img/tucuman_alejandra_arreguez.jpg";
                            break;

                        case "FRENTE DE TODOS":
                            candidato = "Rossana Elena Chahla";
                            candidato2 = "Agustin Fernandez";
                            fotoLista = "/img/tucuman_rossana_elena_chahla.jpg";
                            break;

                        case "FUERZA REPUBLICANA":
                            candidato = "Josefina Maria Bussi";
                            candidato2 = "Mario Gerardo Huesen";
                            fotoLista = "/img/tucuman_josefina_maria_bussi.jpg";
                            break;

                        case "JUNTOS POR EL CAMBIO JXC":
                            candidato = "Roberto Antonio Sanchez";
                            candidato2 = "Paula Omodeo";
                            fotoLista = "/img/tucuman_roberto_antonio_anchezi.jpg";
                            break;
                    }
                    break;

                case "tucuman_s":
                    switch (nombre) {
                        case "FRENTE AMPLIO POR TUCUMÁN":
                            candidato = "Federico Augusto Masso";
                            candidato2 = "Maria Paula Betsabe Mercado";
                            fotoLista = "/img/tucuman_federico_augusto_masso.jpg";
                            break;
                        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
                            candidato = "Martin Correa";
                            candidato2 = "Gabriela Gramajo";
                            fotoLista = "/img/tucuman_martin_correa.jpg";
                            break;
                        case "FRENTE DE TODOS":
                            candidato = "Pablo Raul Yedlin";
                            candidato2 = "Sandra Mariela Mendoza";
                            fotoLista = "/img/tucuman_pablo_raul_yedlin.jpg";
                            break;
                        case "FUERZA REPUBLICANA":
                            candidato = "Ricardo Argentino Bussi";
                            candidato2 = "Liliana Del Valle Leiva";
                            fotoLista = "/img/tucuman_ricardo_argentino_bussi.jpg";
                            break;
                        case "JUNTOS POR EL CAMBIO JXC":
                            candidato = "German Enrique Alfaro";
                            candidato2 = "Beatriz Luisa Avila";
                            fotoLista = "/img/tucuman_german_enrique_alfaro.jpg";
                            break;
                    }
                    break;

                default:
                    candidato = nombre;
                    candidato2 = "";

                    break;
            }

            if (fotoLista == "") {
                fotoLista = "/img/persona.png";
            }
            return [candidato, fotoLista, candidato2];
            // alert(nombre);
        },

        verifica_porcenteje(porcentaje_partido) {
            if (porcentaje_partido == 100) {
                return "";
            } else {
                return porcentaje_partido + " %";
            }
        },

        cambiaColor(idAgrupacion) {
            let agrupacionColor = "";
            switch (idAgrupacion) {
                case "7":
                    agrupacionColor = "rgba(89, 46, 107)";
                    break;
                case "8":
                    agrupacionColor = "rgb(204, 0, 0)";
                    break;
                case "9":
                    agrupacionColor = "rgba(244, 2, 46)";
                    break;
                case "11":
                    agrupacionColor = "rgb(228, 114, 9)";
                    break;

                case "14":
                    agrupacionColor = "rgba(78, 102, 173)";
                    break;
                case "27":
                    agrupacionColor = "rgba(253, 1, 0)";
                    break;
                case "40":
                    agrupacionColor = "rgba(78, 102, 173)";
                    break;
                case "13":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "41":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "98":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "136":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "114":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "150":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "155":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "161":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "168":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "183":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "189":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "195":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "202":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "210":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "215":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "222":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;
                case "243":
                    agrupacionColor = "rgba(233, 23, 48)"; //FIT
                    break;

                case "44":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "12":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "96":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "125":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "134":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "115":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "117":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "142":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "145":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "148":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "153":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "160":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "169":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "176":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "182":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "187":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "198":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "201":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "214":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "228":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "250":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;
                case "241":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
                    break;

                case "137":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
                    break;
                case "151":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
                    break;
                case "151":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
                    break;
                case "179":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
                    break;
                case "185":
                    agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
                    break;

                case "43":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;

                case "11":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "97":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "113":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "154":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "190":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "199":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "223":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "236":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "194":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "135":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "242":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "251":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "170":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "118":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "143":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "146":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "126":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "184":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "209":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "213":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;
                case "159":
                    agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
                    break;

                case "45":
                    agrupacionColor = "rgba(0, 176, 139)";
                    break;
                case "127":
                    agrupacionColor = "rgb(91, 159, 130)"; // VAMOS CON VOS
                    break;

                case "119":
                    agrupacionColor = "rgb(91, 159, 130)"; // VAMOS CON VOS
                    break;
                case "119":
                    agrupacionColor = "rgb(91, 159, 130)"; // VAMOS CON VOS
                    break;

                default:
                    agrupacionColor = "rgba(140, 147, 156)";
                    break;
            }
            return agrupacionColor;
        },
    },
};
</script>
