import Vue from 'vue';
import App from './App.vue';
import VueAnalytics from 'vue-analytics'
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';


import { rtdbPlugin } from 'vuefire';


Vue.config.productionTip = false;
Vue.use(rtdbPlugin);

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.use(VueAnalytics, {
    id: 'UA-9245898-1',
    router,
    autoTracking: {
        pageviewTemplate(route) {
            return {
                page: route.path,
                title: document.title,
                location: window.location.href,
                dimension6: "Elecciones 2021"
            }
        }
    }
})

new Vue({
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');